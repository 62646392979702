
// @ is an alias to /sr
import { defineComponent, onMounted, reactive, toRefs, inject } from "vue";
import AfterLoginPageLayout from "@/views/layouts/AfterLoginPageLayout.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import ToggleCheckbox from "@/components/atomics/ToggleCheckbox.vue";
import Popup from "@/components/popups/Popup.vue";
import HeaderTitleBar from "@/components/parts/HeaderTitleBar.vue";
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";
import {
  RepositoryFactory,
  CompanyRepository,
  OnboardingRepository,
  NotificationRepository,
} from "@/lib/https";
import UpdateLogoPopup from "@/components/popups/organization-detail/UpdateLogoPopup.vue";
import UpdateNamePopup from "@/components/popups/organization-detail/UpdateNamePopup.vue";
import UpdatePhoneNumberPopup from "@/components/popups/organization-detail/UpdatePhoneNumberPopup.vue";
import UpdateRepresentativeNamePopup from "@/components/popups/organization-detail/UpdateRepresentativeNamePopup.vue";
import UpdateAddressPopup from "@/components/popups/organization-detail/UpdateAddressPopup.vue";
import UpdateControlNumberPopup from "@/components/popups/organization-detail/UpdateControlNumberPopup.vue";
import UpdateReferralCompanyPopup from "@/components/popups/organization-detail/UpdateReferralCompanyPopup.vue";
import UpdateReferralPersonNamePopup from "@/components/popups/organization-detail/UpdateReferralPersonNamePopup.vue";
import AddCompanyNotificationReceiversPopup from "@/components/popups/organization-detail/AddCompanyNotificationReceiversPopup.vue";
import { useStore } from "vuex";
import { getFilePathFromUrl } from "@/lib/utility/stringUtil";
import { useI18n } from "vue-i18n";

enum editPopupList {
  UPDATE_LOGO,
  UPDATE_NAME,
  UPDATE_ADDRESS,
  UPDATE_PHONE_NUMBER,
  UPDATE_REPRESENTATIVE_NAME,
  UPDATE_REFERRAL_COMPANY,
  UPDATE_REFERRAL_PERSON_NAME,
  UPDATE_CONTROL_NUMBER,
  UPDATE_IMPRINT,
}

export default defineComponent({
  name: "OrganizationDetail",
  components: {
    AfterLoginPageLayout,
    AvatarCircle,
    TextButton,
    ToggleCheckbox,
    Popup,
    FlatButton,
    HeaderTitleBar,
    UpdateLogoPopup,
    UpdateNamePopup,
    UpdateAddressPopup,
    UpdateControlNumberPopup,
    UpdateReferralCompanyPopup,
    UpdatePhoneNumberPopup,
    UpdateRepresentativeNamePopup,
    UpdateReferralPersonNamePopup,
    AddCompanyNotificationReceiversPopup,
  },
  setup() {
    const state = reactive({
      companyData: {
        name: "",
        logo: "",
        postalCode: "",
        prefectureId: "",
        city: "",
        address: "",
        buildingRoom: "",
        fullAddress: "",
        phoneNumber: "",
        representativeLastName: "",
        representativeFirstName: "",
        referralCompanyName: "",
        referralPersonLastName: "",
        referralPersonFirstName: "",
        controlNumber: "",
        is2FARequired: false,
        imprint: "",
        imprintType: 1,
        imprintSize: 21
      },
      isShownTwoFAPopup: false,
      currentSection: "section1",
      showPopUp: false,
      showAddAuthPopup: false,
      email: "",
      users: [],
      selectedUsers: [],
      authUsers: [],
      selectedEditPopup: -1,
      prefecturesList: [],
      avatarBaseUrl: process.env.VUE_APP_API_CLOUD_URL,
    });

    const isMobile = inject("isMobile");
    const store = useStore();
    const { t } = useI18n();
    const showSuccessNotification = (text: string) => {
      store.commit("notification/showSuccessNotification", text);
    };

    const showErrorNotification = (text: string) => {
      store.commit("notification/showErrorNotification", text);
    };

    const { getCompany, updateTwoFARequired, updateMailAllow } =
      RepositoryFactory.getRepository<CompanyRepository>(CompanyRepository);

    const { getProvinces } =
      RepositoryFactory.getRepository<OnboardingRepository>(
        OnboardingRepository
      );

    const {
      getCompanyNotificationReceivers,
      deleteCompanyNotificationReceivers,
    } = RepositoryFactory.getRepository<NotificationRepository>(
      NotificationRepository
    );

    const handleSubmitChangeField = (data: any) => {
      if (data.errorMessage) {
        showErrorNotification(data.errorMessage);
      } else {
        const { id, ...response } = data;
        state.companyData = {
          ...state.companyData,
          ...response,
        };

        showSuccessNotification(t("notification.updateSuccess"));
      }
    };

    onMounted(async () => {
      const company = await getCompany();
      if (company && Object.keys(company).length > 0) {
        state.companyData = company;
      }


      const prefectures = await getProvinces();
      state.prefecturesList = prefectures;

      state.authUsers = (await getCompanyNotificationReceivers()) as never[];
    });

    const hideEditPopup = () => {
      state.selectedEditPopup = -1;
      state.showPopUp = false;
    };

    const handleToggleTwoFA = (payload: any) => {
      // if (payload) {
      //   state.isShownTwoFAPopup = true;
      // } else {
      //   handleUpdateTwoFA(false);
      // }
      state.isShownTwoFAPopup = true;
    };

    const handleUpdateTwoFA = async (payload: any) => {
      const formData = new FormData();
      formData.append("is2FARequired", payload);
      const response = await updateTwoFARequired(formData);
      if (response) {
        const requiredResponse = {
          is2FARequired: response.is2FARequired,
        };
        handleSubmitChangeField(requiredResponse);
      }
      state.isShownTwoFAPopup = false;
    };

    const handleUpdateMailAllow = async (payload: any) => {
      const formData = new FormData();
      formData.append("isMailNotificationAllowed", payload);
      const response = await updateMailAllow(formData);
      if (response) {
        const requiredResponse = {
          isMailNotificationAllowed: response.isMailNotificationAllowed,
        };
        handleSubmitChangeField(requiredResponse);
      }
    };

    const updateAuthUser = async ({ ...response }: any) => {
      if (response.errorMessage) {
        showErrorNotification(response.errorMessage);
      } else {
        state.authUsers = (await getCompanyNotificationReceivers()) as never[];
        state.showAddAuthPopup = false;
        showSuccessNotification(t("notification.createSuccess"));
      }
    };

    const removeAuthUser = async (user: any) => {
      const index = state.authUsers.indexOf(user as never);
      await deleteCompanyNotificationReceivers(user.id)
        .then((response) => {
          showSuccessNotification(t("notification.deleteSuccess"));
          state.authUsers.splice(index, 1);
        })
        .catch((e) => {
          showErrorNotification(e.data.message);
        });
    };

    // state company info
    const companyInfo = store.getters["template/getCompanyInfo"];

    return {
      ...toRefs(state),
      isMobile,
      hideEditPopup,
      editPopupList,
      handleSubmitChangeField,
      handleToggleTwoFA,
      handleUpdateTwoFA,
      handleUpdateMailAllow,
      getCompanyNotificationReceivers,
      deleteCompanyNotificationReceivers,
      updateAuthUser,
      companyInfo,
      getFilePathFromUrl,
      removeAuthUser,
    };
  },
  computed: {
    getLogoData(): any {
      if (this.selectedEditPopup === editPopupList.UPDATE_LOGO)
        return this.companyData.logo;
      else if (this.selectedEditPopup === editPopupList.UPDATE_IMPRINT)
        return this.companyData.imprint;
      return "";
    },
  },
  methods: {
    showEditPopup(selectedEditPopup: number) {
      this.selectedEditPopup = selectedEditPopup;
      this.showPopUp = true;
    },
    // removeAuthUser(user: any) {
    //   const index = this.authUsers.indexOf(user as never);
    //   this.deleteCompanyNotificationReceivers(user.id);
    //   this.authUsers.splice(index, 1);
    scrollToSection(sectionName: string) {
      const el = this.$refs[sectionName] as HTMLElement;
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
        this.currentSection = sectionName;
      }
    },
  },
});
