
import Popup from "@/components/popups/Popup.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import TableSelect from "@/components/atomics/TableSelect.vue";

import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { NotificationRepository, RepositoryFactory } from "@/lib/https";

export default defineComponent({
  name: "AddCompanyNotificationReceiversPopup",
  components: {
    FlatButton,
    Popup,
    TableSelect,
  },
  emits: ["addAuthUser"],
  setup() {
    const state = reactive({
      isOverflow: false,
      users: [],
      selectedUsers: [],
      authUsers: [],
    });

    const { getUserForNotificationReceivers, addCompanyNotificationReceivers } =
      RepositoryFactory.getRepository<NotificationRepository>(
        NotificationRepository
      );

    onMounted(async () => {
      state.users = (await getUserForNotificationReceivers("")) as never[];
    });

    return {
      ...toRefs(state),
      getUserForNotificationReceivers,
      addCompanyNotificationReceivers,
    };
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.onUpdateLayoutTableSelect();
    });
  },

  methods: {
    updateSelectedUser(payload: any): void {
      this.selectedUsers = payload;
    },
    async addAuthUser() {
      this.selectedUsers.forEach((user) => {
        if (!this.authUsers.includes(user)) this.authUsers.push(user);
      });
      const authUserIds = this.authUsers.map((item) => item["id"]);
      await this.addCompanyNotificationReceivers(authUserIds as []).catch(
        (e) => {
          const requiredResponse = {
            errorMessage: e.data.message,
          };
          this.$emit("addAuthUser", requiredResponse);
        }
      );
      this.$emit("addAuthUser", {});
    },
    removeAuthUser(user: any) {
      const index = this.authUsers.indexOf(user as never);
      this.authUsers.splice(index, 1);
    },

    async onSearchChange(keyword: string) {
      this.users = (await this.getUserForNotificationReceivers(
        keyword
      )) as never[];
    },

    onUpdateLayoutTableSelect() {
      const height = window.innerHeight;
      const popupContainer = this.$refs.popup as HTMLElement;
      const clientHieght = popupContainer?.clientHeight as number;
      if (clientHieght >= height - 210) {
        this.isOverflow = true;
      } else {
        this.isOverflow = false;
      }
    },
  },
});
