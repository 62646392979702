
import { defineComponent, reactive, toRefs } from "vue";
import Popup from "@/components/popups/Popup.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import { useForm } from "vee-validate";
import { RepositoryFactory, CompanyRepository } from "@/lib/https";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "UpdateNamePopup",
  components: {
    FlatButton,
    Popup,
    ValidationTextField,
  },
  props: {
    showPopUp: { type: Boolean, defaul: false },
    editInitValue: { type: String },
  },
  emits: ["hideEditPopup", "handleSubmitChangeField"],
  setup(props, context) {
    const state = reactive({});
    const store = useStore();
    const { t } = useI18n();

    const { updateCompanyName, checkExistName } =
      RepositoryFactory.getRepository<CompanyRepository>(CompanyRepository);
    const form = useForm({
      initialValues: {
        name: props.editInitValue ?? "",
      },
    });

    const { formHandle } = useFormHandler(form, updateCompanyName);

    const { errors, values, submitForm } = form;
    const checkExistCompanyName = async () => {
      await submitForm();
      if (values["name"].trim() != "") {
        const isExist = await checkExistName(values["name"].trim());
        if (isExist != "ok" && values["name"].trim() != props.editInitValue) {
          form.setFieldError("name", t("errors.existName"));
        }
      }
    };

    const submit = async function () {
      const isExist = await checkExistName(values["name"]);
      if (isExist == "ok")
        await formHandle()
          .then(async (response) => {
            if (errors.value["name"]) return;
            if (response) {
              const requiredResponse = {
                name: response.name,
              };
              context.emit("handleSubmitChangeField", requiredResponse);

              // set state name
              await Promise.all([
                store.dispatch("template/setCompany", {
                  name: requiredResponse.name,
                }),
              ]);
              context.emit("hideEditPopup");
            }
          })
          .catch((e) => {
            const requiredResponse = {
              errorMessage: e.data.message,
            };
            context.emit("handleSubmitChangeField", requiredResponse);
          });
    };
    return { ...toRefs(state), submit, checkExistCompanyName };
  },
  methods: {
    hideEditPopup() {
      this.$emit("hideEditPopup");
    },
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */
