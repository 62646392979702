import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05710c58"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "table-area" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableSelect = _resolveComponent("TableSelect")!
  const _component_flat_button = _resolveComponent("flat-button")!
  const _component_popup = _resolveComponent("popup")!

  return (_openBlock(), _createBlock(_component_popup, {
    title: _ctx.$t('organizationDetail.addAuthTitle')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "popup-container",
        style: _normalizeStyle({ 'padding-bottom': _ctx.isOverflow ? '20px' : '4px' }),
        ref: "popup"
      }, [
        _createElementVNode("p", {
          innerHTML: _ctx.$t('organizationDetail.addAuthMessage'),
          class: "message"
        }, null, 8, _hoisted_1),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_TableSelect, {
            class: "user-add-input",
            items: _ctx.users,
            label: _ctx.$t('organizationDetail.addAuthLabel'),
            "search-hint": _ctx.$t('organizationDetail.addAuthSearchHint'),
            "is-create-tag": true,
            "is-enter-to-create-tag": false,
            "item-types": 'GroupUser',
            onOnUpdate: _ctx.updateSelectedUser,
            onOnUpdateLayout: _ctx.onUpdateLayoutTableSelect,
            onOnInput: _ctx.onSearchChange
          }, null, 8, ["items", "label", "search-hint", "onOnUpdate", "onOnUpdateLayout", "onOnInput"])
        ])
      ], 4),
      _createElementVNode("div", {
        class: _normalizeClass([{ 'border-top': _ctx.isOverflow }, "w-full section-footer"])
      }, [
        _createVNode(_component_flat_button, {
          class: "w-full",
          onOnClick: _ctx.addAuthUser,
          text: _ctx.$t('organizationDetail.addAuthButton'),
          enabled: _ctx.selectedUsers.length > 0
        }, null, 8, ["onOnClick", "text", "enabled"])
      ], 2)
    ]),
    _: 1
  }, 8, ["title"]))
}