import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-225f6260"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-container" }
const _hoisted_2 = { class: "text-popup" }
const _hoisted_3 = { class: "input-label" }
const _hoisted_4 = { class: "input-label mt-1" }
const _hoisted_5 = { class: "section-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validation_text_field = _resolveComponent("validation-text-field")!
  const _component_flat_button = _resolveComponent("flat-button")!
  const _component_popup = _resolveComponent("popup")!

  return (_ctx.showPopUp)
    ? (_openBlock(), _createBlock(_component_popup, {
        key: 0,
        title: _ctx.$t('organizationDetail.editRepresentativeName'),
        onOnClickOutsidePopup: _ctx.hideEditPopup,
        onOnHide: _ctx.hideEditPopup
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("organizationDetail.representativeLastNameLabel")), 1),
              _createVNode(_component_validation_text_field, {
                placeholder: _ctx.$t('organizationDetail.companyNameHint'),
                name: "representativeLastName",
                rules: "name|required",
                message: {
            error_required: _ctx.$t('errors.required'),
            error_format_name: _ctx.$t('errors.formatName'),
          }
              }, null, 8, ["placeholder", "message"]),
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("organizationDetail.representativeFirstNameLabel")), 1),
              _createVNode(_component_validation_text_field, {
                placeholder: _ctx.$t('organizationDetail.companyNameHint'),
                name: "representativeFirstName",
                rules: "name|required",
                message: {
            error_required: _ctx.$t('errors.required'),
            error_format_name: _ctx.$t('errors.formatName'),
          }
              }, null, 8, ["placeholder", "message"])
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_flat_button, {
              text: _ctx.$t('organizationDetail.editConfirmButton'),
              class: "confirm-button",
              onClick: _ctx.submit
            }, null, 8, ["text", "onClick"])
          ])
        ]),
        _: 1
      }, 8, ["title", "onOnClickOutsidePopup", "onOnHide"]))
    : _createCommentVNode("", true)
}