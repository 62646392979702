
import { defineComponent, reactive, toRefs } from "vue";

import Popup from "@/components/popups/Popup.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import { useForm } from "vee-validate";
import { RepositoryFactory, CompanyRepository } from "@/lib/https";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";
export default defineComponent({
  name: "UpdatePhoneNumberPopup",
  components: {
    FlatButton,
    Popup,
    ValidationTextField,
  },
  props: {
    showPopUp: { type: Boolean, defaul: false },
    editInitValue: { type: String },
  },
  emits: ["hideEditPopup", "handleSubmitChangeField"],
  setup(props, context) {
    const state = reactive({
      editValue: props.editInitValue,
    });

    const { updateCompanyPhoneNumber } =
      RepositoryFactory.getRepository<CompanyRepository>(CompanyRepository);
    const form = useForm({
      initialValues: {
        phoneNumber: props.editInitValue ?? "",
      },
    });
    const { formHandle } = useFormHandler(form, updateCompanyPhoneNumber);

    const submit = async function () {
      await formHandle()
        .then((response) => {
          if (response) {
            const requiredResponse = {
              phoneNumber: response.phoneNumber,
            };
            context.emit("handleSubmitChangeField", requiredResponse);
            context.emit("hideEditPopup");
          }
        })
        .catch((e) => {
          const requiredResponse = {
            errorMessage: e.data.message,
          };
          context.emit("handleSubmitChangeField", requiredResponse);
        });
    };
    return { ...toRefs(state), submit };
  },
  methods: {
    hideEditPopup() {
      this.$emit("hideEditPopup");
    },
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */
