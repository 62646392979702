import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-066208b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-container" }
const _hoisted_2 = {
  key: 0,
  class: "square-avatar"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "imprint-setting-area"
}
const _hoisted_5 = { class: "shape-setting" }
const _hoisted_6 = {
  key: 0,
  class: "shape-text"
}
const _hoisted_7 = {
  key: 1,
  class: "shape-text"
}
const _hoisted_8 = { class: "section-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AvatarCircle = _resolveComponent("AvatarCircle")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_ToggleCheckbox = _resolveComponent("ToggleCheckbox")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_popup = _resolveComponent("popup")!

  return (_ctx.showPopUp)
    ? (_openBlock(), _createBlock(_component_popup, {
        key: 0,
        title: 
      _ctx.selectedEditPopup === _ctx.editPopupList.UPDATE_IMPRINT
        ? _ctx.$t('organizationDetail.editStamp')
        : _ctx.$t('organizationDetail.editSymbol')
    ,
        onOnClickOutsidePopup: _ctx.hideEditPopup,
        onOnHide: _ctx.hideEditPopup
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "border-dashes-container",
              onDragleave: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
              onDragover: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
              onDrop: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.uploadImage && _ctx.uploadImage(...args)), ["prevent"]))
            }, [
              (_ctx.selectedEditPopup === _ctx.editPopupList.UPDATE_IMPRINT && !_ctx.isRound)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("img", {
                      class: "icon__uploaded",
                      src: _ctx.showTempLogo,
                      alt: "default user icon"
                    }, null, 8, _hoisted_3)
                  ]))
                : (_openBlock(), _createBlock(_component_AvatarCircle, {
                    key: 1,
                    avatar: _ctx.showTempLogo,
                    alt: "Symbol",
                    class: "main-image"
                  }, null, 8, ["avatar"])),
              _createElementVNode("input", {
                type: "file",
                ref: "image",
                accept: "image/png, image/jpeg",
                style: {"display":"none"},
                name: "logo",
                onChange: _cache[0] || (_cache[0] = (e) => _ctx.uploadImage(e))
              }, null, 544),
              _createVNode(_component_TextButton, {
                icon: require('icons/camera.svg'),
                text: _ctx.$t('onboarding.logoText'),
                onOnClick: _ctx.uploadFile,
                class: "child"
              }, null, 8, ["icon", "text", "onOnClick"]),
              _createVNode(_component_TextButton, {
                icon: require('icons/delete-orange.svg'),
                text: _ctx.$t('onboarding.logoDeleteText'),
                onOnClick: _ctx.deleteImage,
                class: "child child-delete"
              }, null, 8, ["icon", "text", "onOnClick"])
            ], 32)
          ]),
          (_ctx.selectedEditPopup === _ctx.editPopupList.UPDATE_IMPRINT)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_ToggleCheckbox, {
                    checked: _ctx.isRound,
                    "onUpdate:checked": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isRound) = $event)),
                    onOnChange: _ctx.onChangeImprintType
                  }, null, 8, ["checked", "onOnChange"]),
                  (!_ctx.isRound)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t("common.square")), 1))
                    : (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.$t("common.circle")), 1))
                ]),
                _createVNode(_component_Dropdown, {
                  class: "size-dropdown",
                  options: _ctx.sizeOptions,
                  "default-option": _ctx.selectedSize,
                  onOnChange: _ctx.updateSize
                }, null, 8, ["options", "default-option", "onOnChange"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.selectedEditPopup === _ctx.editPopupList.UPDATE_LOGO)
              ? (_openBlock(), _createBlock(_component_FlatButton, {
                  key: 0,
                  text: _ctx.$t('organizationDetail.editConfirmButton'),
                  class: "confirm-button",
                  enabled: _ctx.enableBtn,
                  onClick: _ctx.submit_company_logo
                }, null, 8, ["text", "enabled", "onClick"]))
              : (_ctx.selectedEditPopup === _ctx.editPopupList.UPDATE_IMPRINT)
                ? (_openBlock(), _createBlock(_component_FlatButton, {
                    key: 1,
                    text: _ctx.$t('organizationDetail.editConfirmButton'),
                    class: "confirm-button",
                    enabled: _ctx.enableBtn,
                    onClick: _ctx.submit_company_imprint
                  }, null, 8, ["text", "enabled", "onClick"]))
                : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["title", "onOnClickOutsidePopup", "onOnHide"]))
    : _createCommentVNode("", true)
}