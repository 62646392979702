
import { defineComponent, reactive, toRefs } from "vue";
import Popup from "@/components/popups/Popup.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import { useForm } from "vee-validate";
import { RepositoryFactory, CompanyRepository } from "@/lib/https";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";

export default defineComponent({
  name: "UpdateNamePopup",
  components: {
    FlatButton,
    Popup,
    ValidationTextField,
  },
  props: {
    showPopUp: { type: Boolean, default: false },
    editControlNumber: { type: String },
  },
  emits: ["hideEditPopup", "handleSubmitChangeField"],
  setup(props, context) {
    const state = reactive({});

    const { updateControlNumber } =
      RepositoryFactory.getRepository<CompanyRepository>(CompanyRepository);
    const form = useForm({
      initialValues: {
        controlNumber: props.editControlNumber ?? "",
      },
    });
    const { formHandle } = useFormHandler(form, updateControlNumber);

    const submit = async function () {
      await formHandle()
        .then(async (response) => {
          if (response) {
            const requiredResponse = {
              controlNumber: response.controlNumber,
            };
            context.emit("handleSubmitChangeField", requiredResponse);
            context.emit("hideEditPopup");
          }
        })
        .catch((e) => {
          const requiredResponse = {
            errorMessage: e.data.message,
          };
          context.emit("handleSubmitChangeField", requiredResponse);
        });
    };
    return { ...toRefs(state), submit };
  },
  methods: {
    hideEditPopup() {
      this.$emit("hideEditPopup");
    },
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */
