
import { defineComponent, reactive, toRefs } from "vue";
import Popup from "@/components/popups/Popup.vue";
import Dropdown from "@/components/atomics/Dropdown.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import { useForm } from "vee-validate";
import { RepositoryFactory, CompanyRepository } from "@/lib/https";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";

export default defineComponent({
  name: "UpdateAddressPopup",
  components: {
    FlatButton,
    Popup,
    ValidationTextField,
    Dropdown,
  },
  props: {
    showPopUp: { type: Boolean, defaul: false },
    editPostalCode: { type: String },
    editPrefectureId: { type: String },
    editCity: { type: String },
    editAddress: { type: String },
    editBuildingRoom: { type: String },
    prefecturesList: { type: Array, required: true },
  },
  emits: ["hideEditPopup", "handleSubmitChangeField"],
  setup(props, context) {
    const state = reactive({});

    const { updateAddress } =
      RepositoryFactory.getRepository<CompanyRepository>(CompanyRepository);
    const form = useForm({
      initialValues: {
        postalCode: props.editPostalCode ?? "",
        prefectureId: props.editPrefectureId ?? "",
        city: props.editCity ?? "",
        address: props.editAddress ?? "",
        buildingRoom: props.editBuildingRoom ?? "",
      },
    });
    const { formHandle } = useFormHandler(form, updateAddress);

    const submit = async function () {
      await formHandle()
        .then((response) => {
          if (response) {
            const requiredResponse = {
              postalCode: response.postalCode,
              prefectureId: response.prefectureId,
              prefectureName: response.prefectureName,
              city: response.city,
              address: response.address,
              buildingRoom: response.buildingRoom,
              fullAddress: response.fullAddress,
            };
            context.emit("handleSubmitChangeField", requiredResponse);
            context.emit("hideEditPopup");
          }
        })
        .catch((e) => {
          const requiredResponse = {
            errorMessage: e.data.message,
          };
          context.emit("handleSubmitChangeField", requiredResponse);
        });
    };
    return { ...toRefs(state), submit };
  },
  methods: {
    hideEditPopup() {
      this.$emit("hideEditPopup");
    },
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */
