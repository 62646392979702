
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import Popup from "@/components/popups/Popup.vue";
import Dropdown from "@/components/atomics/Dropdown.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import { useForm } from "vee-validate";
import {
  RepositoryFactory,
  CompanyRepository,
  OnboardingRepository,
} from "@/lib/https";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";

export default defineComponent({
  name: "UpdateReferralCompanyPopup",
  components: {
    FlatButton,
    Popup,
    Dropdown,
  },
  props: {
    showPopUp: { type: Boolean, default: false },
    editReferralCompany: { type: String, default: "" },
  },
  emits: ["hideEditPopup", "handleSubmitChangeField"],
  setup(props, context) {
    const state = reactive({
      referralCompanyList: [] as any,
    });

    const { updateReferralCompany } =
      RepositoryFactory.getRepository<CompanyRepository>(CompanyRepository);
    const { getPreferences } =
      RepositoryFactory.getRepository<OnboardingRepository>(
        OnboardingRepository
      );
    const form = useForm({
      initialValues: {
        referralCompanyId: props.editReferralCompany,
      },
    });

    const { formHandle } = useFormHandler(form, updateReferralCompany);

    const submit = async function () {
      await formHandle()
        .then((response) => {
          if (response) {
            const referralCompanyName = state.referralCompanyList.filter(
              (item: any) => item.id === response.referralCompanyId
            )[0].name;
            const requiredResponse = {
              referralCompanyId: response.referralCompanyId,
              referralCompanyName: referralCompanyName,
            };
            context.emit("handleSubmitChangeField", requiredResponse);
            context.emit("hideEditPopup");
          }
        })
        .catch((e) => {
          const requiredResponse = {
            errorMessage: e.data.message,
          };
          context.emit("handleSubmitChangeField", requiredResponse);
        });
    };

    onMounted(async () => {
      state.referralCompanyList = await getPreferences();
    });
    return { ...toRefs(state), submit };
  },
  methods: {
    hideEditPopup() {
      this.$emit("hideEditPopup");
    },
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */
