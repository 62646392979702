
import { defineComponent, reactive, toRefs } from "vue";
import Popup from "@/components/popups/Popup.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import { useForm } from "vee-validate";
import { RepositoryFactory, CompanyRepository } from "@/lib/https";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";

export default defineComponent({
  name: "UpdateReferralPersonNamePopup",
  components: {
    FlatButton,
    Popup,
    ValidationTextField,
  },
  props: {
    showPopUp: { type: Boolean, default: false },
    editFistName: { type: String },
    editLastName: { type: String },
  },
  emits: ["hideEditPopup", "handleSubmitChangeField"],
  setup(props, context) {
    const state = reactive({
      referralPersonFirstName: props.editFistName || "",
      referralPersonLastName: props.editLastName || "",
    });

    const { updateReferralPersonName } =
      RepositoryFactory.getRepository<CompanyRepository>(CompanyRepository);
    const form = useForm({
      initialValues: {
        referralPersonFirstName: state.referralPersonFirstName,
        referralPersonLastName: state.referralPersonLastName,
      },
    });
    const { formHandle } = useFormHandler(form, updateReferralPersonName);

    const submit = async function () {
      await formHandle()
        .then((response) => {
          if (response) {
            const requiredResponse = {
              referralPersonLastName: response.referralPersonLastName,
              referralPersonFirstName: response.referralPersonFirstName,
            };
            context.emit("handleSubmitChangeField", requiredResponse);
            context.emit("hideEditPopup");
          }
        })
        .catch((e) => {
          const requiredResponse = {
            errorMessage: e.data.message,
          };
          context.emit("handleSubmitChangeField", requiredResponse);
        });
    };
    return { ...toRefs(state), submit };
  },
  methods: {
    hideEditPopup() {
      this.$emit("hideEditPopup");
    },
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */
