
import { defineComponent, reactive, toRefs, computed, watch } from "vue";

import Popup from "@/components/popups/Popup.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";
import ToggleCheckbox from "@/components/atomics/ToggleCheckbox.vue";
import Dropdown from "@/components/atomics/Dropdown.vue";

import { RepositoryFactory, CompanyRepository } from "@/lib/https";
import { useStore } from "vuex";
import { resizeImage } from "@/lib/utility/common";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "UpdateLogoPopup",
  components: {
    FlatButton,
    TextButton,
    Popup,
    AvatarCircle,
    ToggleCheckbox,
    Dropdown,
  },
  props: {
    showPopUp: { type: Boolean, defaul: false },
    logo: { type: String },
    selectedEditPopup: { type: Number, required: true },
    imprintOptions: {type: Object, default: {} as any},
    editPopupList: { type: Object, required: true },
  },
  emits: ["handleSubmitChangeField", "hideEditPopup"],
  setup(props, context) {
    const state = reactive({
      logoFileTemp: "",
      companyLogo: props.logo,
      avatarBaseUrl: process.env.VUE_APP_API_CLOUD_URL,
      enableBtn: false,
      isRound: props.imprintOptions?.imprintType === 1,
      selectedSize: props.imprintOptions?.imprintSize || "21",
      sizeOptions: [
        { id: "15", name: "15mm" },
        { id: "18", name: "18mm" },
        { id: "21", name: "21mm" },
        { id: "24", name: "24mm" },
      ],
      deletedImage: false,
    });
    const store = useStore();
    const { t } = useI18n();

    watch(
      () => props.imprintOptions,
      (val: any) => {
        state.selectedSize = val.imprintSize;
        state.isRound = val.imprintType === 1
      }, { deep: true }
    )



    const showTempLogo = computed(() => {
      if (state.logoFileTemp) {
        handleEnableBtn();
        return URL.createObjectURL(state.logoFileTemp as any);
      }
      if (state.companyLogo && state.companyLogo !== "default") {
        return state.avatarBaseUrl + state.companyLogo + `?${Date.now()}`;
      } else {
        if (props.selectedEditPopup === props.editPopupList.UPDATE_IMPRINT) {
          if (state.isRound) return require("images/stamp.svg");
          else return require("images/square-stamp.svg");
        }
        return require("icons/default-company-avatar.svg");
      }
    });

    const handleEnableBtn = () => {
      state.enableBtn = true;
    };

    const uploadImage = async (e: any) => {
      const sourceFile = e.target.files || e.dataTransfer.files;
      const file = sourceFile[0];
      if (file.size > 200000) {
        store.commit(
          "notification/showErrorNotification",
          t("errors.errorOversizeAvatar")
        );
        context.emit("hideEditPopup");
        return;
      }
      // state.logoFileTemp = file;

      const imageResized = (await resizeImage(file)) as any;

      state.logoFileTemp = imageResized.fileUpload;
    };

    const deleteImage = () => {
      state.logoFileTemp = "";
      state.companyLogo = "";
      if (props.logo && props.logo !== "default") {
        handleEnableBtn();
      } else {
        state.enableBtn = false;
      }
      state.deletedImage = true;
    };

    const updateSize = (id: string) => {
      state.selectedSize =
        state.sizeOptions.find((item: any) => item.id === id)?.id || "";
      handleEnableBtn()
    };
    
    const onChangeImprintType = () => {
      handleEnableBtn()
    }

    const { updateCompanyLogo, updateCompanyImprint } =
      RepositoryFactory.getRepository<CompanyRepository>(CompanyRepository);

    const submit_company_logo = async function () {
      const formData = new FormData();
      formData.append("logo", state.logoFileTemp);
      await updateCompanyLogo(formData)
        .then(async (response) => {
          const requiredResponse = {
            logo: response.logo
              ? response.logo + "?" + new Date().getTime()
              : "default",
          };
          context.emit("handleSubmitChangeField", requiredResponse);

          // set state info logo
          await Promise.all([
            store.dispatch("template/setCompany", {
              logo: requiredResponse.logo,
            }),
          ]);
        })
        .catch((e) => {
          context.emit("handleSubmitChangeField", false, e);
        });
      context.emit("hideEditPopup");
    };

    const submit_company_imprint = async function () {
      const formData = new FormData();
      formData.append("imprint", state.logoFileTemp);
      formData.append("imprintType", state.isRound ? "round" : "square");
      formData.append("imprintSize", state.selectedSize);
      if (state.deletedImage) {
        formData.append("deletedImage", state.deletedImage.toString());
      }

      await updateCompanyImprint(formData)
        .then((response) => {
          const requiredResponse = {
            imprint: response.imprint || "",
            imprintSize: response.imprintSize,
            imprintType: response.imprintType
          };
          context.emit("handleSubmitChangeField", requiredResponse);
        })
        .catch((e) => {
          const requiredResponse = {
            errorMessage: e.data.message,
          };
          context.emit("handleSubmitChangeField", requiredResponse);
        });

      context.emit("hideEditPopup");
    };

    return {
      ...toRefs(state),
      showTempLogo,
      uploadImage,
      deleteImage,
      submit_company_logo,
      submit_company_imprint,
      updateSize,
      onChangeImprintType
    };
  },
  methods: {
    hideEditPopup() {
      this.$emit("hideEditPopup");
    },
    uploadFile() {
      let fileInput = this.$refs.image as HTMLInputElement;
      fileInput.click();
    },
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */
